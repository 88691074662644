/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

const client = new ApolloClient({
  cache : new InMemoryCache(),
  link  : new HttpLink({
    uri     : 'https://api.github.com/graphql',
    headers : {
      Authorization : `Bearer ed88429f9cff80e16dc999157a0182fd18c920cb`,
    },
    fetch,
  }),
});
export const wrapRootElement = ({ element }) => <ApolloProvider client={client}>{element}</ApolloProvider>;
